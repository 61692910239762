import React from 'react';
import { Layout } from '../components/shared/Layout';

const IndexPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="text-center">
        <section
          className="py-32 bg-cover bg-center"
          style={{
            backgroundImage: "url('/img/cam-morin-655384-unsplash.jpg')",
          }}
        >
          <div className="container mx-auto max-w-xl">
            <h1 className="my-16 text-white">De Haagse Munt</h1>
            <h3 className="text-white font-normal shadow">
              Handel lokaal! De Haagse Munt is dé nieuwe manier om waarde te
              creëren in onze stad. Betalen met De Haagse Munt zorgt voor meer
              verbondenheid én economische groei in de stad.
            </h3>
          </div>
        </section>

        <section className="py-16">
          <div className="container mx-auto max-w-2xl">
            <h3 className="mb-8 font-normal">
              De nieuwe manier om waarde te creëren in onze stad.
            </h3>
            <div className="grid grid-cols-5 gap-4">
              <div>Geld blijft binnen de lokale economie</div>
              <div>Eenvoudig betalen via mobiel en website</div>
              <div>Interessante mogelijkheden voor sociale doelen</div>
              <div>Gedekt door het Garantiefonds De Haagse Munt</div>
              <div>Iedereen kan meedoen, geen startkosten</div>
            </div>
          </div>
        </section>

        <section
          className="py-32 bg-cover bg-center"
          style={{
            backgroundImage: "url('/img/adult-blur-business-204475.jpg')",
          }}
        >
          <div className="container mx-auto max-w-xl">
            <h1 className="my-16 text-white">De Haagse Munt</h1>
            <p className="text-white font-normal text-2xl">
              De Haagse Munten is er voor die momenten dat je elkaar niet hoeft
              te betalen met Euro&amp;s, maar het eigenlijk ook geen
              vriendendienst is. De oplossing? Maak een aantal Haagse Munten
              over!
            </p>

            <p className="text-white text-2xl font-normal">
              De Haagse Munt is er in de eerste plaats voor de bewoners van onze
              stad. Beloon elkaar met Haagse Munten op momenten dat betalen in
              Euro&amp;s te formeel is. Je weet met de Haagse Munt dat het
              altijd goed besteed is!
            </p>
          </div>
        </section>

        <section className="p-32">
          <div className="container mx-auto">
            <h2>Voor een betere stad, voor iedereen</h2>
            <p className="text-xl">
              Met De Haagse Munt zorgen we ervoor dat er meer lokaal gehandeld
              wordt. De Munt is alleen te besteden in onze stad. Op deze manier
              zorgt de munt voor meer welvaart én welzijn. De munt is er voor
              iedereen die de waarde van onze mooie stad ziet.
            </p>
          </div>
        </section>

        <section
          className="p-32 bg-cover bg-center"
          style={{
            backgroundImage: "url('/img/shoot-n-design-71270-unsplash.jpg')",
          }}
        ></section>

        <section className="p-32">
          <div className="container mx-auto">
            <h2>Vragen? We staan voor je klaar!</h2>
            <p className="text-xl">
              Wil je meer weten, heb je een vraag of opmerking? We staan klaar
              voor je om iedere vraag over De Haagse Munt te beantwoorden.
            </p>
          </div>
        </section>

        <section className="p-32 bg-gray-200">
          <div className="container mx-auto">
            <h2>Wat vindt Den Haag ervan?</h2>
            <div className="grid grid-cols-3 gap-4 mt-8">
              <div>
                <em>
                  Naast Zwolle, Breda, Alkmaar, Groningen, Wageningen en Utrecht
                  is er nu ook in Den Haag een lokale, circulaire munt: De
                  Haagse Munt! Een project dat steun verdient, want een lokale
                  munt is een elegante aanvulling op ons standaard geldcircuit:
                  wanneer je betaalt met circulair geld, versterk je lokale
                  netwerken en ontdek je eenvoudiger nieuwe innovatieve en
                  maatschappelijk betrokken ondernemers in je directe omgeving.
                </em>{' '}
                Robin van Emden
              </div>
              <div>
                <em>
                  Door een lokale munt trekken inwoners het initiatief in de
                  economie weer naar zich toe. Ontzettend mooi dat er een lokale
                  munt is die verschrikkelijk veel waarde kan toevoegen voor
                  onze stad. Ga zo door!
                </em>{' '}
                Marcel Kleizen
              </div>
              <div>
                <em>
                  Velen hebben erover gesproken, anderen hebben het
                  geprobeerd... Maar De Haagse Munt is eindelijk een feit! Er is
                  nog een lange weg te gaan, maar de eerste stappen zijn gezet.
                  Ik ben erg benieuwd wat deze munt voor de stad, haar inwoners
                  en ondernemers zal gaan doen. En vice versa 😉{' '}
                </em>
                Jay Navarro
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default IndexPage;
